import React from "react";

import APIService from "../../../../APIService";

import { CollectionListTopActionBar } from "./CollectionListTopActionBar";
import { CollectionListItem } from "./CollectionListItem";
import { CollectionListBottomActionBar } from "./CollectionListBottomActionBar";
import { CollectionListRefresh } from "./CollectionListRefresh";
import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";

export class CollectionList extends React.Component {
  constructor(props) {
    super(props);
    if (props.authenticated) {
      props.history.push({ pathname: "/" });
    }
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
    this.state = {
      current_collection_day: this.props.location.collectionDay || 7,
      collections: {},
      last_updated: null,
    };
  }

  componentDidMount() {
    this.setCollections();
    if (CompletedCollectionsIndexedDB !== undefined) {
      this.CompletedCollectionsIndexedDB = CompletedCollectionsIndexedDB;
      this.CompletedCollectionsIndexedDB.createCompletedCollectionsIndexDB({
        objectStoreName: "collections",
        version: 3,
        onsuccess: this.completedCollectionSuccess.bind(this),
      });
    }
    if (this.props.location.collectionDay !== undefined) {
      this.getCurrentCollectionDay(this.props.location.collectionDay);
    }
  }

  completedCollectionSuccess(event) {
    if (event.target.result) {
      this.CompletedCollectionsIndexedDB.db = event.target.result;
      this.getCompletedCollections();
    }
  }

  getCompletedCollections() {
    CompletedCollectionsIndexedDB.getCollectionsInStore({
      onsuccess: this.getCompletedCollectionsSuccess.bind(this),
    });
  }

  getCompletedCollectionsSuccess(event) {
    const completedCollections = event.target.result;
    let completed = [];
    if (completedCollections.length > 0) {
      completedCollections.forEach((collection) => {
        completed.push(collection.unique_id);
      });
    }
    this.setState({
      completed_collections: completed,
    });
  }

  getDriver() {
    try {
      var driver = JSON.parse(localStorage.getItem("driver"));
      return driver.id;
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  setCollections() {
    var driverId = this.getDriver();
    APIService.getCollections(driverId).then((data) =>
      this.setState({ collections: data.data, last_updated: new Date() })
    );
  }

  getCurrentCollectionDay(current_collection_day) {
    this.setState({
      current_collection_day: current_collection_day,
    });
  }

  
  render() {
    const days = Object.keys(this.state.collections);
    const fusionOrderURLIndex = days.indexOf("fusion_order_url")
    days.splice(fusionOrderURLIndex, 1)
    let current_collections = [];
    let current_collection_day = ""
    let last_updated_text = "";
    let last_updated = this.state.last_updated;
    if (days.length > 0) {
      current_collection_day = days[this.state.current_collection_day];
      current_collections = this.state.collections[current_collection_day];
    }
    if (last_updated !== null) {
      let day = last_updated.getDate();
      let month = last_updated.toLocaleString("default", { month: "short" });
      let year = last_updated.getFullYear();
      let time = last_updated.toLocaleString("default", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      last_updated_text = `Last updated ${day} ${month} ${year} at ${time}`;
    }
    return (
      <>
        <CollectionListTopActionBar
          days={days}
          setCurrentCollectionDay={this.getCurrentCollectionDay.bind(this)}
          collectionDay={this.state.current_collection_day || 0}
        />
        <div id="collection-list-rows" className="page-content">
          {current_collections.length > 0 &&
            current_collections.map((collection, index) => {
              return (
                <CollectionListItem
                  key={index}
                  {...collection}
                  completedCollections={this.state.completed_collections}
                  collectionDay={
                    this.state.current_collection_day !== null
                      ? this.state.current_collection_day
                      : 7
                  }
                />
              );
            })}
          <CollectionListRefresh
            labelText={last_updated_text}
            onClick={this.setCollections.bind(this)}
          />
        </div>
        {current_collection_day &&
          <CollectionListBottomActionBar
            orderingLink={this.state.collections["fusion_order_url"][current_collection_day]}
            labelButtonRight="Disposal"
            displayButtonRight={true}
            labelButtonLeft=""
            displayButtonLeft={true}
            hideButtonLeft={false}
            typeButtonLeft="driver"
          />
        }

      </>
    );
  }
}
